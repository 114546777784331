import * as React from 'react';
import { BaseChart } from '@/app/stats/BaseChart/BaseChart.component';
import { AnalyticsTable } from '@/app/stats/AnalyticsTable/AnalyticsTable.component';
import { FC, useState } from 'react';
import { ChartWrapper, FilterWrapper, StatsWrapper, TableWrapper } from '@/app/stats/StatsPage.styled';
import { AnalyticsFilter, Filter } from '@/app/stats/AnalyticsFilter/AnalyticsFilter.component';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from '@/core/stores/project.store';
import { Empty } from 'antd';
import { BaseAnalyticsStore } from '@/app/stats/BaseAnalytics/store/baseAnalyticsStore';
import { downloadFile } from '@/common/utils/download-file';
import { kFormatter, kPercentageFormatter } from '@/app/stats/common/formatters';

export const BaseAnalyticsPage: FC<{
    projectStore?: ProjectStore
}> = inject('projectStore')(observer(({projectStore}) => {
    const [analyticsStore] = useState(() => new BaseAnalyticsStore(projectStore));


    const {
        tableData,
        columns,
        isLoading,
        isEmpty,
        selectedRowKeys,
        versions,
        currentFilter,
        relativeChart,
        absoluteChart
    } = analyticsStore;

    const onChange = (selectedRowKeys: string[]) => {
        analyticsStore.onChangeTable(selectedRowKeys);
    }

    const search = (filter: Filter) => {
        analyticsStore.setCurrentFilter(filter);
    }

    const onDownload = () => {
        analyticsStore.downloadCsv().then(result => {
            downloadFile(`export-csv-${analyticsStore.currentFilter.period}-${analyticsStore.currentFilter.periodKey}.csv`, result);
        })
    }

    return <StatsWrapper>
        <FilterWrapper>
            <AnalyticsFilter isEmpty={isEmpty} isLoading={isLoading}
                             onDownload={onDownload}
                             search={search}
                             project={projectStore.choosenProject}/>
        </FilterWrapper>
        <ChartWrapper>
            {!isEmpty ? <div style={{width: '100%'}}>

                    <BaseChart syncId={'syncedId'} formatter={kFormatter} labels={absoluteChart.labels} searchKey={currentFilter.periodKey}
                               versions={versions} max={absoluteChart.max} data={absoluteChart.chartData}
                               keys={absoluteChart.keys} selected={absoluteChart.selected}/>
                    <BaseChart syncId={'syncedId'} formatter={kPercentageFormatter} labels={relativeChart.labels} searchKey={currentFilter.periodKey}
                               versions={versions} max={100} data={relativeChart.chartData}
                               keys={relativeChart.keys} selected={relativeChart.selected}/>

                </div> :
                <Empty description={'Нет данных за выбранный период'} image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </ChartWrapper>
        {!isEmpty && <TableWrapper>
            <AnalyticsTable selectedRowKeys={selectedRowKeys}
                            onChange={onChange} columns={columns}
                            data={tableData}/>
        </TableWrapper>}
    </StatsWrapper>;
}));
