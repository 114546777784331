import { Reaction } from './Reaction';
import { makeObservable, observable } from 'mobx';
import { State } from '@/app/scripts/models/State';
import { Flow } from '@/app/scripts/models/Flow';

export class JumpToReaction extends Reaction {
    static className = 'JumpToReaction';
    @observable jumpNext?: string = '';

    constructor(id: string, name: string) {
        super(id, name);
        makeObservable(this);
    }
}

export const findJumpTo = (n: State | Reaction, flow: Flow) => {
    const jumps: JumpToReaction[] = [];
    flow.forEach((jump) => {
        if (JumpToReaction.is(jump) && (jump as JumpToReaction).jumpNext === (n as (State | Reaction)).id) {
            jumps.push(jump as JumpToReaction);
        }
    });

    return jumps;
};
