import { API } from '../../common/api';
import { Project } from '../../common/models/project';
import { Pagination, PaginationResponse } from '../../common/models/pagination';
import { Group } from '../../common/models/group';
import { SnippetDto } from './models/snippet.dto';
import { JsTemplate } from './models/js-template';
import { ExecutionSnippet } from '@/app/snippets/models/execution-snippet';

export class SnippetsAPI extends API {
    static getAll(project: Project, pagination?: Pagination): Promise<PaginationResponse<SnippetDto>> {
        return SnippetsAPI.get<PaginationResponse<SnippetDto>>(`/projects/${project.id}/facts`, pagination);
    }

    static getSnippetById(project: Project, snippetId:  number): Promise<SnippetDto> {
        return SnippetsAPI.get<SnippetDto>(`/projects/${project.id}/facts/${snippetId}`);
    }

    static getGroups(project: Project, pagination?: Pagination): Promise<Group[]> {
        return SnippetsAPI.get<Group[]>(`/projects/${project.id}/fact-groups`, pagination);
    }

    static createSnippet(project: Project, snippet: SnippetDto): Promise<SnippetDto> {
        return SnippetsAPI.post<SnippetDto>(`/projects/${project.id}/facts`, snippet);
    }

    static createGroup(project: Project, group: Group): Promise<Group> {
        return SnippetsAPI.post<Group>(`/projects/${project.id}/fact-groups`, group);
    }

    static updateGroup(project: Project, group: Group): Promise<Group> {
        return SnippetsAPI.put<Group>(`/projects/${project.id}/fact-groups/${group.id}`, group);
    }

    static removeGroup(project: Project, groupId: number): Promise<void> {
        return SnippetsAPI.delete<Group>(`/projects/${project.id}/fact-groups/${groupId}`);
    }

    static updateSnippet(project: Project, snippet: SnippetDto): Promise<SnippetDto> {
        return SnippetsAPI.put<SnippetDto>(`/projects/${project.id}/facts/${snippet.id}`, {...snippet, id: undefined });
    }

    static patchSnippet(project: Project, snippet: any): Promise<SnippetDto> {
        return SnippetsAPI.patch<any, any>(`/projects/${project.id}/facts/${snippet.id}`, {...snippet, id: undefined });
    }

    static async deleteSnippet(project: Project, snippet: SnippetDto): Promise<void> {
        return SnippetsAPI.delete(`/projects/${project.id}/facts/${snippet.id}`);
    }

    static async getTemplates(): Promise<JsTemplate[]> {
        return SnippetsAPI.get<JsTemplate[]>(`/js-templates`);
    }

    static executeSnippet(project: Project, params: { code: string, facts: Record<string, string> }): Promise<ExecutionSnippet> {
        return SnippetsAPI.post(`/projects/${project.id}/js-execution`, params);
    }
}
