import { action, makeObservable, observable } from 'mobx';

import { LoadableStore } from '@/common/store';
import { ActivityLogsPageApi } from './ActivityLogsPage.api';
import {
    ActivityLog,
    ActivityLogActions,
    ActivityLogComponents,
    ActivityLogFilters,
    ActivityLogFiltersData
} from '@/common/models/activity-logs';

export class ActivityLogsPageStore extends LoadableStore {
    itemsPerPage: number = 50;
    private static readonly loadTimeout: number = 500;
    private static readonly defaultFilter: ActivityLogFilters = {
        dateFrom: undefined,
        dateTo: undefined,
        actions: ActivityLogActions.ALL,
        components: ActivityLogComponents.ALL,
        items: '',
        projectVersions: 0,
        projects: 0,
        users: 0
    };

    @action.bound
    changeItemsPerPage = (newValue: number) => {
        this.itemsPerPage = newValue;
    }

    private filter: ActivityLogFilters = ActivityLogsPageStore.defaultFilter;

    @observable isApplying: boolean = false;
    @observable isResetting: boolean = false;
    @observable isLoading: boolean = true;
    @observable isLoadingNext: boolean = false;
    @observable page: number;
    @observable total: number;
    @observable logs: ActivityLog[] = [];
    @observable logsSource: Record<keyof ActivityLog, any>[] = [];
    @observable draftFilter: ActivityLogFilters = ActivityLogsPageStore.defaultFilter;
    @observable filtersData: ActivityLogFiltersData;

    constructor() {
        super();
        makeObservable(this);
    }

    async _load(): Promise<void> {
        this.isLoading = true;
        this.logs = await this.fetchLogs(ActivityLogsPageStore.defaultFilter);
        this.filtersData = await ActivityLogsPageApi.getLogsFilters(ActivityLogsPageStore.defaultFilter);
        this.isLoading = false;
    }

    async reload() {
        this.page = 1;
        this.filter = Object.assign({}, ActivityLogsPageStore.defaultFilter);
        await super.reload();
    }

    @action.bound
    async resetFilter() {
        this.isResetting = true;
        await this._applyFilter(ActivityLogsPageStore.defaultFilter);
        setTimeout(() => { this.isResetting = false; }, ActivityLogsPageStore.loadTimeout);
    }

    @action.bound
     async applyFilter(filter: ActivityLogFilters) {
        this.isApplying = true;
        this.filter = filter;
        await this._applyFilter(filter);
        setTimeout(() => { this.isApplying = false; }, ActivityLogsPageStore.loadTimeout);
    }

    async loadMore(page: number) {
        if (this.page >= this.total) return;
        this.isLoadingNext =  true;
        this.page = page
        try {
            this.logs = await this.fetchLogs(this.filter);
            this.isLoadingNext = false;
        } catch(e) {
            this.isLoadingNext = false;
        }
    }

    @action.bound
    private async _applyFilter(filter: ActivityLogFilters) {
        this.page = 1;
        this.logs = await this.fetchLogs(filter);
    }

    private async fetchLogs(filter: ActivityLogFilters) {
        const { itemsPerPage } = this;
        const resp = await ActivityLogsPageApi.getLogs(this.page, filter, itemsPerPage);
        this.total = resp.pageCount * this.itemsPerPage;
        this.page = resp.currentPage;
        return resp.results.map(r => {
            r.date = new Date(r.date);
            return r;
        });
    }
}
