import React, { useState } from 'react';
import cn from './LoginForm.module.scss';
import cns from 'classnames';
import { SignupDto } from '../../models/signup.dto';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';

interface LoginFormProps {
    onSignUp: (data: SignupDto) => void;
    onCancel: () => void;
    errors: Record<string, string[]>;
    isLoading: boolean;
}

export const SignUpForm = ({onCancel, onSignUp, errors, isLoading}: LoginFormProps) => {
    const [email, onChangeEmail] = useState('');
    const {t} = useTranslation();
    return (
        <form className="form" onSubmit={e => {
            e.preventDefault();
            onSignUp({email})
        }}>
            <div className="form__form-group">
                <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                        <Input
                            className={cn.input}
                            name="name"
                            onChange={e => onChangeEmail(e.target.value)}
                            type="text"
                            placeholder={t('auth.email')}
                        />
                        {
                            errors['email'] && errors['email'].map((error, key) =>
                                <span className="form__form-group-error" key={key}>{t('auth.email_invalid')}</span>)
                        }
                        {
                            errors['conflict'] && errors['conflict'].map((error, key) =>
                                <span className="form__form-group-error" key={key}>{t('auth.signup_conflict')}</span>)
                        }
                    </div>
                </div>
            </div>
            <div className={cns('account__btns', cn.footer)}>
                <Button onClick={() => onSignUp({email})}
                        style={{flex: 1}}
                        size={'large'}
                        disabled={isLoading}
                >{t(isLoading ? 'auth.while_process' : 'auth.create_account')}
                </Button>
                <Button type={'primary'}
                        size={'large'}
                        style={{flex: 1}}
                        onClick={onCancel}>
                    {t('actions.cancel')}
                </Button>
            </div>
        </form>
    );
};

