import React, { useState } from 'react';
import { LoginForm } from './LoginForm';
import cn from './Login.module.scss';
import { inject, observer } from 'mobx-react';
import { UserStore } from '@/core/stores/user.store';
import { SignUpForm } from './SignUpForm';
import { Tracker } from '@/core/analytics/tracker';
import { useNavigate } from 'react-router-dom/dist';


type LoginProps = {
    user?: UserStore;
}

const _Login = (props: LoginProps) => {
    const {user} = props;
    const [errors, onChangeErrors] = useState<Record<string, string[]>>({});
    const [isLoginForm, onChangeForm] = useState<boolean>(true);
    const [isLoading, onChangeLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const handlerWrapper = (func: (data: any) => Promise<void>, type: string) => async (data: any) => {
        onChangeErrors({});
        onChangeLoading(true);
        try {
            await func(data);
            onChangeLoading(false);
            navigate('/')
            Tracker.trackEvent(type, {status: 'success', Type: 'Email'});
        } catch (e) {
            onChangeErrors(Object.assign({}, e, {status_code: undefined}));
            onChangeLoading(false);
            Tracker.trackEvent(type, {status: 'fail', Type: 'Email'});
        }
    };

    const clearErrors = () => {
        onChangeErrors({});
    }

    return <div className="account">
        <div className={cn.leftLiaBaloon}/>
        <div className={cn.rightLiaBaloon}/>
        <div className={cn.logo}/>
        <div className="account__wrapper">
            <div className="account__card">
                {
                    isLoginForm
                        ? <LoginForm onLogin={handlerWrapper(user!.login, 'Login')}
                                     errors={errors}
                                     clearErrors={clearErrors}/>
                        : <SignUpForm onSignUp={handlerWrapper(user!.signUp, 'Signup')}
                                      isLoading={isLoading}
                                      onCancel={() => {
                                          clearErrors();
                                          onChangeForm(true)
                                      }}
                                      errors={errors}/>
                }
            </div>
        </div>
    </div>
};

export const Login = inject('user')(observer(_Login));
