import React from 'react';
import { inject, observer } from 'mobx-react';
import cn from './Params.module.scss';
import { action, computed, makeObservable, observable } from 'mobx';
import { LabelsStore } from '../stores/labels.store';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ScriptPageStore } from '../stores/script-page.store';
import { JumpToReaction } from '../models/reactions/JumpToReaction';
import { Label } from '../models/Label';
import { Script } from '../models/Script';
import { Form, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { CopyId } from '@/app/scripts/components/CopyId/CopyId.component';

interface JumpToReactionParamsEditorProps extends WithTranslation {
    reaction: JumpToReaction;
    scriptPageStore?: ScriptPageStore;
    labels?: LabelsStore;
}

@inject('scriptPageStore', 'labels')
@observer
export class JumpToReactionParamsEditorComp extends React.Component<JumpToReactionParamsEditorProps> {
    @observable private _labels: Array<Label> = [];
    @observable selectedScript?: Script;
    @observable selectedLabel?: Label;
    @observable isLoaded: boolean = false;

    constructor(props: JumpToReactionParamsEditorProps) {
        super(props);
        makeObservable(this);
        // reaction(() => this.sc)
        this.load();
    }

    get labels(): Array<Label> {
        return this._labels.filter(l => l.id !== this.props.reaction.id);
    }

    set labels(labels: Array<Label>) {
        this._labels = labels.filter(l => l.id !== this.props.reaction.id);
    }

    @computed get scripts() {
        let scripts: Array<Script>;

        if (this.props.scriptPageStore.script.id) {
            scripts = this.props.labels!.scripts;
        } else {
            scripts = [...this.props.labels!.scripts, this.props.scriptPageStore.script];
        }

        return scripts.slice().sort((s1, s2) => s1.name > s2.name ? 1 : -1);
    }

    async load() {
        const {jumpNext} = this.props.reaction;
        await this.props.labels!.load();
        if (jumpNext) {
            const label = this.props.labels!.get(jumpNext)!;
            if (label) {
                this.selectedScript = label.script;
                this.labels = await this.props.labels!.getLabelsForScript(this.selectedScript);
                this.selectedLabel = label;
            }
        } else {
            this.selectedScript = undefined;
            this.selectedLabel = undefined;
        }

        this.isLoaded = true;
    }

    @action.bound
    private onChangeScript = async (id: number) => {
        if (id === -1) {
            this.selectedScript = this.props.scriptPageStore.script;
            this.labels = this.props.scriptPageStore.script.data.labels;
            return;
        }

        this.selectedScript = this.props.labels!.scripts.find(s => s.id === id);
        if (this.selectedScript) {
            this.labels = this.selectedScript.id === this.props.scriptPageStore!.script.id
                ? this.props.scriptPageStore!.script.labels
                : await this.props.labels!.getLabelsForScript(this.selectedScript);

            this.selectedLabel = undefined;
        }
    }

    @action.bound
    onChange = (script: LabeledValue) => {
        const id = script.value;
        return this.onChangeScript(+id);
    }

    @action.bound
    onChangeLabel = (label: LabeledValue) => {
        const id = label.value;
        this.selectedLabel = this.labels.find(l => l.id === id)!;

        this.props.reaction.jumpNext = this.selectedLabel.id;
    }

    // Чтобы не закрывалась правая менюшка
    stopPropagation(e: React.MouseEvent) {
        e.stopPropagation();
    }

    render() {
        if (!this.isLoaded) {
            return null;
        }

        return <div className={cn.editor}>
            <div className={cn.group}>
                <h6 className={cn.groupTitle}>
                    {this.props.t('flows.reactions.jump_to')}
                </h6>
                <Form.Item>
                    <Select
                        defaultValue={this.selectedScript ? {
                            value: this.selectedScript?.id,
                            label: this.selectedScript?.name
                        } : null}
                        onClick={this.stopPropagation}
                        showSearch
                        placeholder={this.props.t('flows.select_flow')}
                        optionFilterProp="children"
                        notFoundContent={this.props.t('flows.not_found_flow')}
                        fieldNames={{value: 'id', label: 'name'}}
                        onChange={this.onChange}
                        labelInValue
                        filterOption={(input, option) => {
                            return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }}
                        options={this.scripts.filter(script => !!script.id)}/>
                </Form.Item>

                <Form.Item>
                    <Select
                        defaultValue={this.selectedLabel ? {
                            value: this.selectedLabel?.id,
                            label: this.selectedLabel?.name
                        } : null}
                        onClick={this.stopPropagation}
                        showSearch
                        placeholder={this.props.t('flows.select_node')}
                        optionFilterProp="children"
                        notFoundContent={this.props.t('flows.not_found_node')}
                        fieldNames={{value: 'id', label: 'name'}}
                        onChange={this.onChangeLabel}
                        labelInValue
                        filterOption={(input, option) => {
                            return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }}
                        options={this.labels.map(label => {
                            return {...label, name: this.props.t(label.name)}
                        })}/>
                </Form.Item>
            </div>
            <CopyId id={this.props.reaction.id}/>
        </div>
    }
}

export const JumpToReactionParamsEditor = withTranslation()(JumpToReactionParamsEditorComp);
