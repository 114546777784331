import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { inject, observer } from 'mobx-react';
import { format } from 'date-fns';

import { get } from 'lodash-es';

import cn from './ActivityLogsPage.module.scss';
import { Page } from '@/common/components/page/Page';
import { ActivityLogsPageStore } from '@/app/activity-logs/ActivityLogsPage.store';
import { ActivityLogsFilter } from '@/app/activity-logs/ActivityLogsFilter';
import { ActivityLogFilters } from '@/common/models/activity-logs';
import { Empty, Spin, Table, TableColumnsType } from 'antd';

const getSorter = (key: string) => {
    return (a: any, b: any) => {
        return get(a, key, '').localeCompare(get(b, key));
    }
}

const getDateSorter = (key: string) => {
    return (a: any, b: any) => {
        const dateA = get(a, key);
        const dateB = get(b, key);

        return new Date(dateA).getTime() - new Date(dateB).getTime();
    }
}
export const ActivityLogsPage: FC<{
    activityLogsPageStore?: ActivityLogsPageStore
}> = inject('activityLogsPageStore')(observer(({
                                                   activityLogsPageStore
                                               }) => {
    const {t} = useTranslation();
    useEffect(() => {
        activityLogsPageStore.reload();
    }, [activityLogsPageStore]);

    const columns: TableColumnsType<any> = [{
        title: t('activity_logs.labels.user'),
        dataIndex: 'user',
        key: 'user',
        render: (user: any) => <a>{user.firstName} {user.lastName}</a>,
        width: '200px',
        sorter: getSorter('user.firstName'),
    }, {
        title: t('activity_logs.labels.action'),
        dataIndex: 'action',
        key: 'action',
        width: '100px',
        sorter: getSorter('action'),
    }, {
        title: t('activity_logs.labels.component'),
        dataIndex: 'component',
        key: 'component',
        width: '140px',
        sorter: getSorter('component'),
    }, {
        title: t('activity_logs.labels.item'),
        dataIndex: 'item',
        key: 'item',
        width: '350px',
        sorter: getSorter('item'),
    }, {
        title: t('activity_logs.labels.date'),
        dataIndex: 'date',
        key: 'date',
        render: (date: any) => format(new Date(date), 'HH:mm:ss dd.MM.yyyy'),
        width: '200px',
        sorter: getDateSorter('date'),
    }, {
        title: t('activity_logs.labels.projectVersion'),
        dataIndex: 'projectVersion',
        key: 'projectVersion',
        render: (projectVersion: any) => projectVersion ? projectVersion.name : '-',
        width: '100px',
        sorter: getSorter('projectVersion.name'),
    }, {
        title: t('activity_logs.labels.project'),
        dataIndex: 'project',
        key: 'project',
        render: (project: any) => project ? project.name : '-',
        sorter: getSorter('project.name')
    }]

    const applyFilter = (filter: ActivityLogFilters) => activityLogsPageStore.applyFilter(filter);
    return <Page editorPadding={false} hideShadow={true} className={cn.wrapper}>
        {activityLogsPageStore.isLoading && <div className={cn.loaderWrapper}><Spin size={'large'}/></div>}
        {!activityLogsPageStore.isLoading && <article className={cn.container}>
            <h1 className={cn.title}>
                {t('activity_logs.title')}
            </h1>
            <div className={cn.activityLogsFilter}>
                <ActivityLogsFilter
                    filter={activityLogsPageStore.draftFilter}
                    filterData={activityLogsPageStore.filtersData}
                    isApplying={activityLogsPageStore.isApplying}
                    isResetting={activityLogsPageStore.isResetting}
                    onApply={applyFilter}
                    onReset={activityLogsPageStore.resetFilter}
                />
            </div>
            {!activityLogsPageStore.logs.length && <div className={cn.activityLogsItems}>
                <Empty description={'Нет данных'} image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            </div>
            }
            {activityLogsPageStore.logs.length && <div className={cn.activityLogsItems}>
                <Table loading={activityLogsPageStore.isLoadingNext} rowHoverable={false}
                       scroll={{y: 'calc(100vh - 500px)'}} showSorterTooltip={true} rowSelection={null} pagination={{
                    pageSize: activityLogsPageStore.itemsPerPage,
                    total: activityLogsPageStore.total,
                    showSizeChanger: true,
                    pageSizeOptions: [50, 100, 150],
                    locale: {items_per_page: 'элементов'},
                    onChange: (page, size) => {
                        activityLogsPageStore.changeItemsPerPage(size);
                        activityLogsPageStore.loadMore(page);
                    }
                }} columns={columns} dataSource={activityLogsPageStore.logs}/>
            </div>
            }
        </article>}
    </Page>
}));
