import React, { FC } from 'react';

import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import {
    ActivityLogFilters,
    ActivityLogFiltersData
} from '@/common/models/activity-logs';
import { getUserName } from '@/common/utils/get-user-name';
import { Button, Select, Form, Space, DatePicker } from 'antd';
import { LabeledValue } from 'antd/es/select';
const { RangePicker } = DatePicker;
interface ActivityLogsFilterProps extends WithTranslation {
    filter: ActivityLogFilters;
    filterData: ActivityLogFiltersData;
    isApplying: boolean;
    isResetting: boolean;
    onApply: (filter: ActivityLogFilters) => void;
    onReset: () => void;
}

type SelectFiltersData = Record<keyof ActivityLogFiltersData, LabeledValue[]>;

const processData = (fd: ActivityLogFiltersData): SelectFiltersData => {
    const simpleFields: (keyof ActivityLogFiltersData)[] = ['actions', 'components', 'items'];
    const result: SelectFiltersData = {
        actions: [],
        components: [],
        items: [],
        projectVersions: [],
        projects: [],
        users: []
    };

    simpleFields.forEach(key => {
        // @ts-ignore
        result[key] = fd[key].map(d => ({ value: d, name: d }));
    });

    result.projects = fd.projects.map(d => ({ value: d.id, label: d.name }));
    result.projectVersions = fd.projectVersions.map(d => ({ value: d.id, label: d.name }));
    result.users = fd.users.map(d => ({ value: d.id, label: getUserName(d) }));

    return result;
};

const ActivityLogsFilterComp: FC<ActivityLogsFilterProps> = props => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const fd = props.filterData && processData(props.filterData);

    const reset = () => {
        form.resetFields();
        props.onReset();
    }
    return (
        <Form form={form} onFinish={(values) => {
            const [dateFrom, dateTo] = values?.date ?? [];
            const result = {
                ...values,
                dateFrom,
                dateTo
            };

            delete result['date'];
            props.onApply(result);
        }}>
            <Space.Compact style={{ gap: '10px' }} block={true}>
                <Form.Item name="users"  style={{ flex: 1 }}>
                    <Select
                        placeholder={t('activity_logs.filter.user')}
                        options={fd['users']}
                    />
                </Form.Item>
                <Form.Item name="actions"  style={{ flex: 1 }}>
                    <Select
                        placeholder={t('activity_logs.filter.action')}
                        options={fd['actions']}
                    />
                </Form.Item>
                <Form.Item name="components"  style={{ flex: 1 }}>
                    <Select
                        placeholder={t('activity_logs.filter.component')}
                        options={fd['components']}
                    />
                </Form.Item>
                <Form.Item name="items"  style={{ flex: 1 }}>
                    <Select
                        placeholder={t('activity_logs.filter.item')}
                        options={fd['items']}
                    />
                </Form.Item>
            </Space.Compact>

            <Space.Compact block={true} style={{ gap: '10px' }}>

                <Form.Item name="date" style={{ flex: 1 }}>
                    <RangePicker placeholder={[t('activity_logs.filter.date_from'), t('activity_logs.filter.date_to')]} style={{ width: '100%' }}></RangePicker>
                </Form.Item>

                <Form.Item name="projectVersions"  style={{ flex: 1 }}>
                    <Select
                        placeholder={t('activity_logs.filter.projectVersion')}
                        options={fd['projectVersions']}
                    />
                </Form.Item>

                <Form.Item name="projects" style={{ flex: 1 }}>
                    <Select
                        placeholder={t('activity_logs.filter.project')}
                        options={fd['projects']}
                    />
                </Form.Item>

                <Form.Item style={{ flex: 1 }}>
                    <Space.Compact block={true}>
                        <Button
                            htmlType={'submit'}
                            style={{ flex: 1, borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }}
                            type={'primary'}
                            disabled={props.isApplying}
                            loading={props.isApplying}
                        >
                            {props.t('actions.ok')}
                        </Button>
                        <Button
                            style={{ flex: 1 }}
                            disabled={props.isResetting}
                            onClick={reset}
                            loading={props.isResetting}
                        >
                            {props.t('actions.reset')}
                        </Button>
                    </Space.Compact>
                </Form.Item>

            </Space.Compact>
        </Form>
    );
};

export const ActivityLogsFilter = withTranslation()(ActivityLogsFilterComp);
