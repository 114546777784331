import React from 'react';
import { Page } from '@/common/components/page/Page';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import cn from './IntentPage.module.scss';
import TooltipText from 'mdi-react/TooltipTextIcon';
import { EditableText } from '@/common/components/EditableText';
import { inject, Observer, observer, Provider } from 'mobx-react';
import cns from 'classnames';
import { IntentExample } from './IntentExample';
import { EntityStore } from '../../entities/entity.store';
import { IntentsPageStore } from '../intents-page.store';
import { SaveButton, SaveButtonState } from '@/common/components/save-button/SaveButton';
import { action, computed, makeObservable, observable } from 'mobx';
import { IntentStore } from '../intent.store';
import { generatePath, } from 'react-router';

import { TestChat } from '../../chat/components/TestChat';
import { Entity } from '../../entities/models/entity';
import { EntityEditor } from './EntityEditor';
import _ from 'lodash';
import { Loader } from '@/common/components/Loader';
import { ChatStore } from '../../chat/chat.store';
import { RightMenu } from '../../components/right-menu/RightMenu';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { Permission, UserStore } from '@/core/stores/user.store';
import { PermissionsView } from '@/app/permissions/Permissions';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { Button, Space, Switch, Input, Form } from 'antd';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';

const {TextArea} = Input;

interface IntentsPageProps extends Partial<RouteComponentProps<{ id: string; projectId: string; }>>, WithTranslation {
    entityStore?: EntityStore;
    intentStore?: IntentStore;
    chatStore?: ChatStore;
    user?: UserStore;
}

@inject('entityStore', 'intentStore', 'user')
@observer
class IntentsPageComponent extends React.Component<IntentsPageProps> {
    private readonly store: IntentsPageStore;
    @observable titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'intents.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error',
    };

    @observable errorEntityMessage: string = '';

    @computed get hasEntities() {
        return this.store.intent.intent_examples
            .some(example => example.parts
                .some(part => part.entity_id != null && part.entity_id != 0)
            );
    }

    @computed get entities(): Entity[] {
        return _.uniqBy(
            this.store.intent.intent_examples
                .flatMap(example => example.parts.map(part => part.entity_id))
                .filter(entityId => entityId && entityId > 0)
                .map(id => this.props.entityStore!.entities.find(e => e.id === id)!)
                .concat(this.store.newEntities),
            i => i.id
        );
    }

    constructor(props: IntentsPageProps) {
        super(props);
        makeObservable(this);
        this.store = new IntentsPageStore(props.entityStore!, props.intentStore!);
        this.onChangeProps(props);

    }

    UNSAFE_componentWillReceiveProps(props: IntentsPageProps) {
        this.onChangeProps(props);
    }

    onChangeProps(props?: IntentsPageProps) {
        if (!props) {
            props = this.props;
        }
        this.store.setIntent(props.params.id ? +props.params.id : undefined);
    }

    componentDidMount() {
        this.props.entityStore!.load();
    }

    saveIntent = async () => {
        const validEntity = this.entities.every(entity => !!entity.name);
        if (!validEntity) {
            this.errorEntityMessage = 'Fill entity names';
            return;
        }

        Tracker.trackEvent('Save', {Object: 'intent', Name: this.store.intent.name});

        this.errorEntityMessage = '';
        const intent = await this.store.save();
        const {params: {projectId}} = this.props.match;
        const replacePath = generatePath('/app/:projectId/intent/:id', {projectId, id: intent.id.toString()});
        this.props.history!.replace(replacePath);
    };

    @action
    toggleArchive = () => {
        if (!this.store.intent.id) {
            return;
        }

        const isActive = this.store.intent.is_active;

        this.props.intentStore!.updateIntent({...this.store.intent, is_active: !isActive});
        this.store.intent.is_active = !isActive;
        Tracker.trackEvent('Edit', {Object: 'intent', Type: 'archive'});
    };

    delete = async () => {
        Tracker.trackEvent('Edit', {Object: 'intent', Type: 'delete'});
        await this.props.intentStore!.removeIntent(this.store.intent);
        const {params: {projectId}} = this.props.match;

        this.props.history!.replace(`/app/${projectId}/intent`);
    };

    duplicate = async () => {
        const cloneIntent = _.cloneDeep(this.store.intent);
        delete cloneIntent.id;
        cloneIntent.name = `${cloneIntent.name} (clone)`;
        this.props.intentStore!.saveIntent(cloneIntent);
        Tracker.trackEvent('Edit', {Object: 'intent', Type: 'duplicate'});
    };

    renderActionMenu() {
        return this.props.user.permissions.isEditIntents && <ActionsMenu
            right={
                <Space size={[12, 0]} wrap>
                    {
                        this.store.intent.id &&
                        <>
                            <Space size={[4, 0]} wrap>
                                {this.props.t(this.store.intent.is_active ? 'Включен' : 'Выключен')}
                                <Switch checked={this.store.intent.is_active} size={'small'}
                                        onChange={this.toggleArchive}/>
                            </Space>

                            <Button title="Дублировать" onClick={this.duplicate}
                                    icon={<CopyOutlined/>}/>

                            <ConfirmDelete title={this.props.t('actions.delete_intent')}
                                           question={this.props.t('actions.delete_element', {name: this.store.intent.name})}
                                           onConfirm={() => this.delete()}>
                                <Button title="Удалить" icon={<DeleteOutlined/>}/>
                            </ConfirmDelete>

                            {/*<button className="btn btn-link link"*/}
                            {/*        onClick={this.toggleArchive}>*/}
                            {/*    {this.props.t(this.store.intent.is_active ? 'actions.archive' : 'actions.unarchived')}*/}
                            {/*</button>*/}
                        </>
                    }
                    <Observer>
                        {() =>
                            <SaveButton onClick={this.saveIntent} state={this.store.saveState}
                                        titlesByState={this.titlesByState}
                                        disabled={!this.store.canSave}/>
                        }
                    </Observer>
                </Space>
            }/>
    }

    renderTip() {
        return <div className={cn.tip}>
            <TooltipText size={48}/>
            <p>
                {this.props.t('intents.mark_entities')}<br/>
                {this.props.t('intents.from_text')}:<br/>
                <b>{this.props.t('intents.hint_intent_text')} <span
                    className={cn.selected}>{this.props.t('intents.hint_intent_date')}</span></b>
            </p>
        </div>
    }

    renderEntityList() {
        return <div className={cn.entityList}>
            <h5 className={cn.entityHeader}>{this.props.t('entities.entities')}</h5>
            <div className={cn.editor}>
                <div>
                    <Observer>
                        {() => <>{this.entities.map(entity => <EntityEditor entity={entity} key={entity.id}/>)}</>}
                    </Observer>
                </div>
                <span className={cn.errorMessage}>{this.errorEntityMessage}</span>
            </div>
        </div>
    }

    renderRightBar() {
        return <RightMenu
            content={this.hasEntities ? this.renderEntityList() : this.renderTip()}
            chat={<TestChat/>}
            contentMaxHeight={'324px'}
        />;
    }

    onPasteSynonym = (e: React.ClipboardEvent) => {
        const text = e.clipboardData.getData('Text');
        const list: string[] = text.split(/\r?\n/).filter(item => item);
        if (list.length > 1) {

            this.store.onExampleMultiAdd(list);
            setTimeout(() => this.store.exampleText = '');
        }
    };

    renderExampleForm() {
        return <PermissionsView permission={Permission.EDIT_INTENTS}>
            <Form>
                <div className="form__form-group">
                <span className={cns('form__form-group-label', cn.formLabel)}>
                    {this.props.t('intents.what_is_say')}
                </span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-input-wrap">
                            <TextArea placeholder={this.props.t('intents.new_phrase')}
                                      onPaste={this.onPasteSynonym}
                                      autoSize={{ minRows: 1, maxRows: 5 }}
                                      value={this.store.exampleText}
                                      onKeyDown={e => {
                                          if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
                                              e.preventDefault();
                                              this.store.onExampleAdd();
                                          }
                                      }}
                                      onChange={e => this.store.exampleText = e.target.value}/>
                        </div>
                    </div>
                </div>
            </Form>
        </PermissionsView>
    }

    renderExamplesList() {
        return <div className={cn.examplesList}>
            {this.store.intent.intent_examples
                .map((example, i) => (
                        <IntentExample editedExample={this.store.editedExample} editEnd={this.store.editEnd} editable={this.props.user.permissions.isEditIntents} example={example} key={i}
                                       onRemove={this.store.onRemoveExample} onTryEdit={this.store.onTryEdit}/>
                    )
                )}
        </div>
    }

    render() {
        return <Provider store={this.store}>
            {
                this.store.intent
                    ? <Page
                        actionMenu={
                            <>
                                {
                                    this.props.user.permissions.isEditIntents && <ActionsMenu
                                        right={
                                            <Space size={[12, 0]} wrap>
                                                {
                                                    this.store.intent.id &&
                                                    <>
                                                        <Space size={[4, 0]} wrap>
                                                            {this.props.t(this.store.intent.is_active ? 'Включен' : 'Выключен')}
                                                            <Switch checked={this.store.intent.is_active} size={'small'}
                                                                    onChange={this.toggleArchive}/>
                                                        </Space>

                                                        <Button title="Дублировать" onClick={this.duplicate}
                                                                icon={<CopyOutlined/>}/>

                                                        <ConfirmDelete title={this.props.t('actions.delete_intent')}
                                                                       question={this.props.t('actions.delete_element', {name: this.store.intent.name})}
                                                                       onConfirm={() => this.delete()}>
                                                            <Button title="Удалить" icon={<DeleteOutlined/>}/>
                                                        </ConfirmDelete>

                                                        {/*<button className="btn btn-link link"*/}
                                                        {/*        onClick={this.toggleArchive}>*/}
                                                        {/*    {this.props.t(this.store.intent.is_active ? 'actions.archive' : 'actions.unarchived')}*/}
                                                        {/*</button>*/}
                                                    </>
                                                }
                                                <Observer>
                                                    {() =>
                                                        <SaveButton onClick={this.saveIntent} state={this.store.saveState}
                                                                    titlesByState={this.titlesByState}
                                                                    disabled={!this.store.canSave}/>
                                                    }
                                                </Observer>
                                            </Space>
                                        }/>
                                }
                            </>
                        }
                        rightBar={this.renderRightBar()}>
                        <div className={cn.header}>
                            <EditableText text={this.store.intent.name} onEdit={(name) => {
                                this.store.isTitleEdited = true;
                                this.store.changeName(name);
                            }} editable={this.props.user.permissions.isEditIntents} className={cn.title}/>
                            <div className={cn.headerButtons}>
                                <button className={cn.entitiesViewToggle}
                                        onClick={this.store.toggleView}>{this.props.t('intents.entities_view')}</button>
                            </div>
                        </div>
                        {this.renderExampleForm()}
                        {this.renderExamplesList()}
                    </Page>
                    : <Loader/>
            }
        </Provider>
    }
}

export const Intents = withTranslation()(withRouter(IntentsPageComponent));
