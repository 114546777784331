import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'antd';

const {TextArea} = Input;

interface QAFormProps {
    onAddAnswer: (answer: string) => void
}

export const QAForm = ({onAddAnswer}: QAFormProps) => {
    const [answer, onChangeAnswer] = useState('');
    const {t} = useTranslation();

    return <form className="material-form" onSubmit={e => {
        e.preventDefault();
        if (answer) {
            onAddAnswer(answer);
            onChangeAnswer('');
        }
    }}>
        <div className="form__form-group" style={{margin: 0}}>
            <span className="form__form-group-label">{t('qa.add_new_answer')}</span>
            <div className="form__form-group-field">
                <div className="form__form-group-input-wrap" style={{ marginBottom: '20px' }}>
                    <TextArea
                        autoSize={{minRows: 1, maxRows: 5}}
                        value={answer}
                        onKeyDown={e => {
                            if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
                                event.preventDefault();
                                if (answer) {
                                    onAddAnswer(answer);
                                    onChangeAnswer('');
                                }
                            }
                        }}
                        onChange={e => onChangeAnswer(e.target.value)}/>
                </div>
            </div>
        </div>
    </form>
};
