import React from 'react';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import { EntityStore } from '../entity.store';
import { generatePath } from 'react-router';
import { EntityHttpRequest } from './entities/EntityHttpRequest';
import { EntityRegExp } from './entities/EntityRegExp';
import { EntityLocation } from './entities/EntityLocation';
import { Page } from '@/common/components/page/Page';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import { EntityKeywords } from './entities/EntityKeywords';
import cn from './Entity.module.scss';
import { EditableText } from '@/common/components/EditableText';
import { Entity as EntityModel } from '../models/entity';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { TestChat } from '../../chat/components/TestChat';
import { RightMenu } from '../../components/right-menu/RightMenu';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { UserStore } from '@/core/stores/user.store';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import { Select, Space, Form, Button } from 'antd';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { DeleteOutlined } from '@ant-design/icons';

interface EntityProps extends RouteComponentProps<{ id: string, type: string, projectId: string }>, WithTranslation {
    entityStore?: EntityStore;
    user?: UserStore;
}

interface ExtractorView {
    label: string;
    value: string;
    description: string;
}

@inject('entityStore', 'user')
@observer
export class EntityComp extends React.Component<EntityProps> {
    @observable selectedExtractorKey: ExtractorView | undefined;
    @observable entityModelToSave: any = {};
    @observable extractors: ExtractorView[] = [];
    @observable entity: EntityModel = {name: ''};
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'entities.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error'
    };


    constructor(props: EntityProps) {
        super(props);
        makeObservable(this);
        this.updateWhenPropsChanged();
    }

    UNSAFE_componentWillReceiveProps(props: EntityProps) {
        this.updateWhenPropsChanged(props);
    }

    updateWhenPropsChanged(props?: EntityProps) {
        this.selectedExtractorKey = undefined;

        if (!props) {
            props = this.props;
        }

        const {match: {params: {id, type}}} = props;

        this.extractors = props.entityStore!.extractors
            .filter(extractor => id !== 'new' ? true : extractor.is_active)
            .map(extractor => {
                return {
                    label: extractor.name,
                    value: extractor.key,
                    description: `entities.extractors.${extractor.key}`
                };
            });

        if (type) {
            this.selectedExtractorKey = this.extractors.find(extractor => type === extractor.value);
        }

        if (id !== 'new') {
            this.entity = this.props.entityStore!.getEntityById(+id)!;
            if (!this.entity) {
                const {params: {projectId}, path} = this.props.match;
                const replacePath = generatePath(path, {projectId, id: 'new'});
                this.props.history.replace(replacePath);
            }
        } else {
            this.entity = {
                name: 'New entity',
                extractor_type: type
            };
        }

    }

    onChangeSelectedEntity = (extractor: any) => {
        const {params: {projectId}} = this.props.match;
        const replacePath = generatePath('/app/:projectId/entities/:id/:type', {
            projectId,
            id: 'new',
            type: extractor.value
        });
        if (extractor) {
            this.props.history.replace(replacePath);
            this.selectedExtractorKey = extractor;
            this.entity.extractor_type = extractor.value;
        } else {
            this.props.history.replace(replacePath);
        }
    };

    onChangeEntityModel = (modelToSave: any) => {
        this.entityModelToSave = modelToSave;
    };

    deleteEntity = async () => {
        Tracker.trackEvent('Edit', {Object: 'entity', Type: 'delete'});
        const {params: {projectId}} = this.props.match;
        await this.props.entityStore!.removeEntity(this.entity);
        this.props.history.replace(`/app/${projectId}/entities`);
    };

    saveEntity = async () => {
        Tracker.trackEvent('Save', {Object: 'entity'});

        if (this.selectedExtractorKey!.value === 'keywords') {
            const isInValid = this.entityModelToSave.keywords
                .some((keyword: any) => {
                    if (!keyword.words.length) {
                        keyword.invalid = true;
                        return true;
                    }
                    return false;
                });

            if (isInValid) {
                this.entity.extractor_params = this.entityModelToSave;
                return false;
            }
        }
        this.entity.extractor_params = this.entityModelToSave;
        this.saveState = SaveButtonState.process;

        try {
            this.entity = await this.props.entityStore!.saveEntity(this.entity);
            this.saveState = SaveButtonState.saved;
            const {params: {projectId}} = this.props.match;
            const replacePath = generatePath('/app/:projectId/entities/:id/:type', {
                projectId,
                id: this.entity.id.toString(),
                type: this.entity.extractor_type
            });
            this.props.history.replace(replacePath);
        } catch (e) {
            this.saveState = SaveButtonState.error;
        } finally {
            setTimeout(() => {
                this.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
        }
    };

    onEdit = (value: string) => {
        this.entity.name = value;
        if (this.entity.id) {
            this.props.entityStore!.patchEntity({name: this.entity.name, id: this.entity.id});
        }
    };

    renderActions() {
        return this.props.user.permissions.isEditEntities && <ActionsMenu right={
            <Space size={[12, 0]} wrap>
                <ConfirmDelete title={this.props.t('actions.delete_entity')}
                               question={this.props.t('actions.delete_element', {name: this.entity.name})}
                               onConfirm={() => this.deleteEntity()}>
                    <Button title="Удалить" icon={<DeleteOutlined/>}/>
                </ConfirmDelete>

                <SaveButton onClick={this.saveEntity} state={this.saveState} titlesByState={this.titlesByState}/>
            </Space>
        }/>
    }

    render() {
        if (!this.entity) {
            return '';
        }
        return <Page actionMenu={
            this.renderActions()
        } rightBar={<RightMenu chat={<TestChat/>}/>}>
            <div className={cn.header}>
                <EditableText className={cn.entityTitle} text={this.entity.name}
                              onEdit={this.onEdit}
                              editable={this.props.user.permissions.isEditEntities}/>
            </div>
            <div className={cn.entitySubtitle}>{this.props.t('entities.select_entity_type')}</div>

            <Form.Item>
                <Select
                    style={{width: '100%'}}
                    placeholder={this.props.t('entities.search_an_entity')}
                    value={this.selectedExtractorKey}
                    labelInValue
                    onChange={this.onChangeSelectedEntity}

                >
                    {this.extractors.map(extractor => <Select.Option key={extractor.value} value={extractor.value}
                                                                     label={extractor.label}>
                        <Space direction="vertical" size="small">
                            <span>{extractor.label}</span>

                            <span className={cn.entitySubtitle}>{this.props.t(extractor.description)}</span>
                        </Space>
                    </Select.Option>)}
                </Select>
            </Form.Item>

            {this.selectedExtractorKey?.value === 'HTTPRequest' &&
                <EntityHttpRequest canEdit={this.props.user.permissions.isEditEntities}
                                   modelToSave={this.entity.extractor_params}
                                   onChange={this.onChangeEntityModel}/>}
            {this.selectedExtractorKey?.value === 'keywords' &&
                <EntityKeywords canEdit={this.props.user.permissions.isEditEntities}
                                modelToSave={this.entity.extractor_params}
                                onChange={this.onChangeEntityModel}/>}
            {this.selectedExtractorKey?.value === 'JSRegExp' &&
                <EntityRegExp canEdit={this.props.user.permissions.isEditEntities}
                              modelToSave={this.entity.extractor_params}
                              onChange={this.onChangeEntityModel}/>}
            {this.selectedExtractorKey?.value === 'location' &&
                <EntityLocation canEdit={this.props.user.permissions.isEditEntities}
                                modelToSave={this.entity.extractor_params}
                                onChange={this.onChangeEntityModel}/>}
        </Page>
    }
}

export const Entity = withTranslation()(withRouter(EntityComp));
