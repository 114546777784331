import React, { ClipboardEvent, KeyboardEvent } from 'react';
import { inject, observer } from 'mobx-react';
import { QAStore } from '../../qa.store';
import { generatePath } from 'react-router';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import cn from './qa.module.scss';
import pcn from '../qa-page.module.scss';
import cns from 'classnames';
import { QA as QAModel } from '../../models/qa';

import { action, computed, makeObservable, observable } from 'mobx';
import { EditableText } from '@/common/components/EditableText';
import { QAForm } from '../add-qa/qa-form';

import CrossIcon from 'mdi-react/CloseIcon';
import CircleIcon from 'mdi-react/CircleIcon';
import PlusIcon from 'mdi-react/PlusIcon';
import { TextField } from '@mui/material';
import { Page } from '@/common/components/page/Page';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { TestChat } from '@/app/chat/components/TestChat';
import EditIcon from 'mdi-react/EditIcon';
import { Loader } from '@/common/components/Loader';
import _ from 'lodash';
import { RightMenu } from '@/app/components/right-menu/RightMenu';
import { IntentStore } from '@/app/intents/intent.store';
import { v4 } from 'uuid';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { ScriptsStore } from '@/app/scripts/stores/scripts.store';
import { State } from '@/app/scripts/models/State';
import { IntentTrigger } from '@/app/scripts/models/triggers/IntentTrigger';
import { TextReaction } from '@/app/scripts/models/reactions/TextReactions';
import { Flow } from '@/app/scripts/models/Flow';
import { MapperFactory } from '@/app/scripts/mappers/MapperFactory';
import { Script } from '@/app/scripts/models/Script';
import { Permission, UserStore } from '@/core/stores/user.store';
import { PermissionsView } from '@/app/permissions/Permissions';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { Button, Space, Switch, Input, Form } from 'antd';
import { BranchesOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
const { TextArea } = Input;

interface QAProps extends RouteComponentProps<{ id: string, projectId: string; }>, WithTranslation {
    store: QAStore;
    intentStore: IntentStore;
    scriptsStore: ScriptsStore
    userStore: UserStore;
}

@inject(({
             qa,
             intentStore,
             scriptsStore,
             user
         }: { qa: QAStore, intentStore: IntentStore, scriptsStore: ScriptsStore, user: UserStore }) => ({
    store: qa,
    intentStore,
    scriptsStore,
    userStore: user
}))
@observer
export class QAComp extends React.Component<QAProps> {
    @observable newQuestionText = '';
    @observable addAnswerFormOpen = true;
    @observable isTitleEdited = false;
    // @ts-ignore
    @observable qa: QAModel;
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Partial<Record<SaveButtonState, string>> = {
        [SaveButtonState.default]: 'qa.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved'
    };
    @observable editIndex: number = -1;
    @observable editAnswerIndex: number = -1;
    questionTextInput?: Element;

    @computed get canSave(): boolean {
        return Boolean(this.qa.answers.length && this.qa.questions.length);
    }

    @computed get isNewQA(): boolean {
        return !this.qa.id;
    }

    constructor(props: QAProps) {
        super(props);
        makeObservable(this);
        this.updateWhenPropsChanged();
    }

    @action.bound
    onPasteQa = (e: ClipboardEvent) => {
        const text = e.clipboardData.getData('Text');
        const list: string[] = text.split(/\r?\n/).filter(item => item);
        if (list.length > 1) {
            this.qa.questions.push(
                ...list.map(item => {
                    return {text: item}
                })
            );
            // TODO: how to fix firing change event after paste
            setTimeout(() => this.newQuestionText = '');
        }
    };

    UNSAFE_componentWillReceiveProps(props: QAProps) {
        this.updateWhenPropsChanged(props);
    }

    async updateWhenPropsChanged(props?: QAProps) {
        if (!props) {
            props = this.props;
        }
        this.isTitleEdited = false;
        this.editIndex = -1;
        const {match, store} = props;
        if (match.params.id !== 'new') {
            this.qa = store.qas.find(item => item.id === +match.params.id)!;
            if (!this.qa) {
                // ??? типы?
                // @ts-ignore
                this.qa = await store.getQAByIdAsync(+match.params.id);
                console.warn('типы кривые');
            }
            this.isTitleEdited = true;
            this.addAnswerFormOpen = false;
        } else {
            this.qa = {
                id: undefined,
                date_created: undefined,
                name: this.props.t('qa.new_answer'),
                answers: [],
                questions: [],
                qa_group_id: 0,
                is_active: true
            };
        }
    }

    @action.bound
    onEdit(value: string) {
        this.isTitleEdited = true;
        this.qa.name = value;
        if (this.qa.id) {
            this.props.store.patchQA({id: this.qa.id, name: this.qa.name});
        }
    }

    @action.bound
    onAddAnswer = (answer: string) => {
        this.qa.answers.push({text: answer});
        this.addAnswerFormOpen = false;
    }

    @action.bound
    onQuestionAdd() {
        if (!this.newQuestionText) {
            return;
        }

        this.editIndex = -1;

        if (!(this.qa.questions.length || this.isTitleEdited)) {
            this.qa.name = this.newQuestionText;
            this.isTitleEdited = true;
        }

        this.qa.questions.push({text: this.newQuestionText});
        this.newQuestionText = '';
    }

    renderQuestionForm() {
        return <Form className="material-form">
            <div className="form__form-group">
                <span className={cns('form__form-group-label', cn.formLabel)}>{this.props.t('qa.add_question')}</span>
                <div className="form__form-group-field">
                    <div className="form__form-group-input-wrap">
                        <TextArea
                            value={this.newQuestionText}
                            onPaste={this.onPasteQa}
                            autoSize={{ minRows: 1, maxRows: 5 }}
                            onChange={e => {
                                this.newQuestionText = e.target.value
                            }}
                            onKeyDown={e => {
                                if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
                                    e.preventDefault();
                                    this.onQuestionAdd();
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </Form>
    }

    @action.bound
    async onSave() {
        Tracker.trackEvent('Save', {Object: 'qa'});
        this.editAnswerIndex = -1;
        this.editIndex = -1;
        this.qa.questions = this.qa.questions.filter(it => it.text?.length > 0);
        this.qa.answers = this.qa.answers.filter(it => it.text?.length > 0);
        this.saveState = SaveButtonState.process;
        const _qa = await this.props.store.saveQA(this.qa);
        this.saveState = SaveButtonState.saved;
        setTimeout(() => {
            this.saveState = SaveButtonState.default
        }, SaveButtonWaitingToDefaultTimeout);
        this.qa = _qa;
        if (_qa.id) {
            const {params: {projectId}, path} = this.props.match;
            const replacePath = generatePath(path, {projectId, id: _qa.id});
            this.props.history.replace(replacePath);
        }
    }

    @action.bound
    async onRemove() {
        Tracker.trackEvent('Edit', {Object: 'qa', Type: 'delete'});
        await this.props.store.removeQA(this.qa);
        this.props.history.replace(`/app/${this.props.match.params.projectId}/qa`);

    }

    @action.bound
    async toggleArchive() {
        Tracker.trackEvent('Edit', {Object: 'qa', Type: 'archive'});
        await this.props.store.updateQA({...this.qa, is_active: !this.qa.is_active});
    }

    duplicate = () => {
        Tracker.trackEvent('Edit', {Object: 'qa', Type: 'duplicate'});
        const cloneQa = _.cloneDeep(this.qa);
        delete cloneQa.id;
        cloneQa.name = `${cloneQa.name} (clone)`;
        this.props.store!.saveQA(cloneQa);
    };

    convertToFlow = async () => {
        Tracker.trackEvent('ConvertToFlow', {Object: 'qa', QaName: this.qa.name});
        const intent = await this.props.intentStore.createIntent({
            name: this.qa.name!,
            intent_examples: this.qa.questions.map(qa => ({parts: [{text: qa.text}]})),
            is_active: true
        });

        const state = new State(v4(), 'State 1', []);

        const trigger = new IntentTrigger();
        trigger.intents = [{id: intent.id!}];

        const reaction = new TextReaction(v4(), 'Text 1');

        this.qa.answers.forEach(answer => {
            reaction.texts.push(answer.text);
        });

        trigger.next = reaction;
        state.triggers.push(trigger);

        const flow = new Flow();
        flow.next = state;


        const script = new Script(0, `${this.qa.name} Flow`, 'flow0.1', flow, true, new Date());
        // @ts-ignore
        const mapper = MapperFactory.buildMapper(script);
        const createdFlow = await this.props.scriptsStore.save(mapper.toDTO(script));

        await this.toggleArchive();

        this.props.history.push(`/app/${this.props.match.params.projectId}/flows/${createdFlow.id}`);
    };

    renderActionMenu() {
        return this.props.userStore.permissions.isEditQa && <ActionsMenu
            right={
                <Space size={[12, 0]} wrap>
                    {
                        !this.isNewQA && <>
                            <Space size={[4, 0]} wrap>
                                {this.props.t(this.qa.is_active ? 'Включен' : 'Выключен')}
                                <Switch checked={this.qa.is_active} size={'small'} onChange={this.toggleArchive} />
                            </Space>

                            <Button title="Дублировать" onClick={this.duplicate}
                                    icon={<CopyOutlined/>}/>

                            <ConfirmDelete title={this.props.t('actions.delete_qa')}
                                           question={this.props.t('actions.delete_element', {name: this.qa.name})}
                                           onConfirm={() => this.onRemove()}>
                                <Button title="Удалить" icon={<DeleteOutlined/>}/>
                            </ConfirmDelete>

                        </>
                    }

                    <PermissionsView permission={Permission.EDIT_QA}><SaveButton
                        className={cns(cn[`fill${this.qa.answers.length}`])}
                        onClick={this.onSave}
                        state={this.saveState}
                        titlesByState={this.titlesByState}
                        disabled={!this.canSave}/></PermissionsView>
                </Space>}
            left={
                !this.isNewQA && <Button title={this.props.t('qa.convert_to_flow')} onClick={this.convertToFlow}
                                         icon={<BranchesOutlined/>}/>
            }
        />;
    }

    @action.bound
    onKeyDown(event: KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter' && !event.ctrlKey && !event.shiftKey) {
            event.preventDefault();
            this.editAnswerIndex = -1;
        }
    }

    renderEditor() {
        const linkClass = cns('btn btn-link', cn.link);
        const showQuestionForm = this.qa.answers.length > 0 ? this.addAnswerFormOpen : true;

        return <div className={cn.editor}>
            <div className={cn.header}>
                <EditableText text={this.qa.name!} onEdit={this.onEdit} className={cns(pcn.title, cn.title)}
                              editable={this.props.userStore.permissions.isEditQa}
                />

            </div>
            <div className={cn.answers}>
                {
                    this.qa.answers.map((answer, i) => (
                        <div className={cn.answer} key={i}>
                            {this.editAnswerIndex === i &&
                                <form className={cn.editForm}>
                                    <TextField autoFocus={true} className={cn.editInput}
                                               onChange={e => answer.text = e.target.value}
                                               multiline={true}
                                               rows={1}
                                               maxRows={4}
                                               onKeyDown={this.onKeyDown}
                                               value={answer.text}/>
                                </form>}

                            {this.editAnswerIndex !== i && <pre className={cn.answerText}>{answer.text}</pre>}

                            <PermissionsView permission={Permission.EDIT_QA}><span className={cn.answerRemove}>
                                {this.editAnswerIndex !== i && <span onClick={() => {
                                    this.editAnswerIndex = i;
                                }}>
                                    <EditIcon className={cn.editQuestionIcon} size={14}/>
                                </span>}
                                <span onClick={() => {
                                    this.qa.answers.splice(i, 1);
                                }}>
                                    <CrossIcon size={14}/>
                                </span>
                            </span></PermissionsView>
                        </div>
                    ))
                }
            </div>
            <div className={cn.addAnswerWrapper}>
                {
                    <PermissionsView permission={Permission.EDIT_QA}>
                        {showQuestionForm
                            ? <QAForm onAddAnswer={this.onAddAnswer}/>
                            : <button className={cns(linkClass, cn.addAnswer)}
                                      onClick={() => this.addAnswerFormOpen = true}>{this.props.t('qa.add_another_answer')}</button>}</PermissionsView>
                }
            </div>
            {
                (!!this.qa.questions.length || !!this.qa.answers.length) && <div className={cn.questions}>
                    <PermissionsView permission={Permission.EDIT_QA}>
                        <div className={cn.questionForm}>
                            <PlusIcon size={18} color={'#116FFC'}/>
                            {
                                this.renderQuestionForm()
                            }
                        </div>
                    </PermissionsView>
                    {
                        this.qa.questions.map((question, i) => (
                            <div className={cn.question} key={i}>
                                <CircleIcon size={9} className={cn.questionDot}/>
                                {this.editIndex === i &&
                                    <form className={cn.editForm} onSubmit={() => this.editIndex = -1}>
                                        <TextField autoFocus={true} className={cn.editInput}
                                                   onChange={e => question.text = e.target.value}
                                                   value={question.text}/></form>}
                                {this.editIndex !== i && <p className={cn.text}>{question.text}</p>}

                                <PermissionsView permission={Permission.EDIT_QA}><span
                                    className={cn.questionClose}>
                                    {this.editIndex !== i &&
                                        <span className={cn.editQuestionIcon} onClick={() => this.editIndex = i}>
                                        <EditIcon size={14}/>
                                    </span>}
                                    <span onClick={() => {
                                        this.qa.questions.splice(i, 1);
                                        this.editIndex = -1;
                                    }}>
                                        <CrossIcon size={14}/>
                                    </span>
                                </span></PermissionsView>
                            </div>
                        ))
                    }
                </div>
            }
        </div>
    }

    render() {
        return this.qa && <Page
            actionMenu={this.renderActionMenu()}
            rightBar={<RightMenu chat={<TestChat/>}/>}
        >
            {this.renderEditor()}
        </Page> || <Loader/>
    }
}

export const QA = withTranslation()(withRouter(QAComp));
// interface QAProps1 extends RouteComponentProps<{ id: string, projectId: string; }>, WithTranslation {
//     store: QAStore;
//     intentStore: IntentStore;
//     scriptsStore: ScriptsStore
//     userStore: UserStore;
// }
// export const QA1: FC<QAProps1> = inject(({
//                                    qa,
//                                    intentStore,
//                                    scriptsStore,
//                                    user
//                                }: { qa: QAStore, intentStore: IntentStore, scriptsStore: ScriptsStore, user: UserStore }) => ({
//     store: qa,
//     intentStore,
//     scriptsStore,
//     userStore: user
// }))(observer(({
//     store, intentStore, scriptsStore, userStore
//                                                      }) => {
//     return '';
// }))
