import React, { useState } from 'react';
import cns from 'classnames';
import cn from './Reset.module.scss';
import cnLogin from '../login/Login.module.scss';
import cnLoginForm from '../login/LoginForm.module.scss';
import { AuthAPI } from '../../auth.api';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom/dist';
import { Input, Button, Form } from 'antd';
import { useEmail, useRequired } from '@/common/utils/form-validators';
export const Reset = () => {
    const location = useLocation();
    const [errors, onChangeErrors] = useState<Record<string, string[]>>({});
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    if (location.state?.email) {
        form.setFieldValue('email', location.state.email);
    }

    const onReset = async (email: string) => {
        try {
            await AuthAPI.reset({ email });
            onChangeErrors({});
            navigate('/auth/login');
        } catch (e) {
            onChangeErrors(Object.assign({}, e, { status_code: undefined }));
        }
    };

    const clearErrors = () => onChangeErrors({});

    const emailRule = useEmail();
    const required = useRequired();


    return <div className="account">
        <div className={cnLogin.leftLiaBaloon}/>
        <div className={cnLogin.rightLiaBaloon}/>
        <div className={cnLogin.logo}/>
        <div className="account__wrapper">
            <div className="account__card card-body">
                <div className={cns("card__title", cn.title)}>
                    <h4>{t('auth.reset_your_password')}</h4>
                    <div className={cns(cn.subtitle)}>
                        { t('auth.enter_the_email') }
                    </div>
                </div>
                <Form onValuesChange={clearErrors} form={form} onFinish={(values) => {
                    onReset(values.email);
                }}>
                    <Form.Item style={{ flex: 1 }} name={'email'} rules={[emailRule, required]}>
                        <Input
                            size={'large'}
                            name="name"
                            type="text"
                            placeholder={ t('auth.email') }
                        />
                    </Form.Item>
                    <div>
                        {
                            errors['email'] && errors['email'].map((error, key) =>
                                <span className="form__form-group-error" key={key}>{t('auth.email_invalid')}</span>)
                        }
                        {
                            errors['conflict'] && errors['conflict'].map((error, key) =>
                                <span className="form__form-group-error" key={key}>{error}</span>)
                        }
                        {
                            errors['user_not_found'] && errors['user_not_found'].map((error, key) =>
                                <span className="form__form-group-error" key={key}>{t('auth.user_not_found')}</span>)
                        }
                    </div>

                    <div className={cns("account__btns", cnLoginForm.footer)}>
                        <Button
                            size={'large'}
                            style={{ flex: 1 }}
                            onClick={() => navigate(-1)}>
                            {t('actions.cancel')}
                        </Button>
                        <Button size={'large'}
                                type={'primary'}
                                style={{ flex: 1 }}
                                htmlType={'submit'}>{ t('auth.send_recovery') }
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
};
