import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import cls from 'classnames';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';

import cn from './Header.module.scss'

import { HeaderProject } from './HeaderProject';
import { Permission, UserStore } from '@/core/stores/user.store';
import { ReactComponent as DocsIcon } from './imgs/docs.svg';
import { PermissionsView } from '@/app/permissions/Permissions';

interface HeaderProps extends  WithTranslation {
    user: UserStore;
    isMinimal?: boolean;
}

export const HeaderComponent = ({ user, isMinimal, t }: HeaderProps) => {
    const navigate = useNavigate();
    const params = useParams();

    const { userInfo } = user;
    const moveToProfile = () => {
        navigate(`/app/${params.projectId}/profile`);
    };

    const moveToActivityLogs = () => {
        navigate(`/app/${params.projectId}/activity-logs`)
    };

    const moveToTeamMembers = (invite?: boolean) => {
        // if (invite) {
        //     setSearchParams('invite=true');
        // }
        // navigate(`/app/${params.projectId}/team`, {
        //
        // })

        navigate({
            pathname: `/app/${params.projectId}/team`,
            search: invite ? 'invite=true' : ''
        });
    }

    const signOut = async () => {
        await user.logout();
        navigate('/auth');
    };

    const num = (Math.abs(userInfo.id) % 8) + 1;
    const avatarClass = cn[`headerProfileIcon--bg-${num}`];
    const firstName = userInfo.first_name;
    const lastName = userInfo.last_name;
    const smallName = `${firstName && firstName[0]}${lastName && lastName[0]}`;

    return (
        <div className={cls(cn.header, isMinimal && cn.headerMinimal)}>
            <Link to={'/'} aria-label={'Move to home'} className={cn.logo}/>
            <div className={cn.headerProject}>
                {!isMinimal && <HeaderProject />}
            </div>
            <div className={cn.headerSpacer} />
            <div className={cn.headerControls}>
                <a
                    className={cn.headerControlsDocs}
                    href="https://liachat.gitbook.io/docs/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <DocsIcon />
                </a>
                <div className={cn.headerControlsDivider} />
            </div>

            <UncontrolledDropdown className={cn.headerProfileAvatar}>
                <DropdownToggle tag="button" className={cls(cn.headerProfileIcon, avatarClass)}>{ smallName }</DropdownToggle>
                <DropdownMenu
                    modifiers={[preventOverflow]}
                    className={cls('dropdown__menu', cn.headerProfileMenu)}
                >
                    <DropdownItem disabled className={'dropdown-item--title'}>
                        {`${userInfo.first_name} ${userInfo.last_name}`}
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem className={cn.headerProfileMenuItem} onClick={moveToProfile}>
                        {t('profile_select.profile_settings')}
                    </DropdownItem>
                    <PermissionsView permission={Permission.MANAGE_TEAM}>
                        <DropdownItem className={cn.headerProfileMenuItem} onClick={() => moveToTeamMembers()}>
                            {t('profile_select.members')}
                        </DropdownItem>
                        <DropdownItem className={cn.headerProfileMenuItem} onClick={() => moveToActivityLogs()}>
                            {t('profile_select.activity')}
                        </DropdownItem>
                    </PermissionsView>
                    <DropdownItem divider />
                    <DropdownItem className={'dropdown-item--control'} onClick={signOut}>
                        {t('profile_select.sign_out')}
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};
 export default withTranslation()(HeaderComponent);
